import { useEffect, useState } from "react";
import {
  connectWallet,
  getCurrentWalletConnected,
  mintRelic,
  mintPixaNFT,
  tokenURIfinder,
  checkPixaBalance,
  mintThreeWarg,
  checkWargsMinted,
  claimVortical,
  claimVorticalLoop,
  upgradeGem,
  checkDopplers
} from "./utils/interact.js";
import pixagif from 'D:/NFT_development/front_end/new-app-pages/relics/nft-minter-tutorial-main/minter-starter-files/src/pixa-logo.gif'
import pixapng from 'D:/NFT_development/front_end/new-app-pages/relics/nft-minter-tutorial-main/minter-starter-files/src/pixa-logo-2.png'
import frostblade from 'D:/NFT_development/front_end/new-app-pages/relics/nft-minter-tutorial-main/minter-starter-files/src/frost-blade.gif'
import rubybow from 'D:/NFT_development/front_end/new-app-pages/relics/nft-minter-tutorial-main/minter-starter-files/src/ruby-bow.gif'
import cursedcauldron from 'D:/NFT_development/front_end/new-app-pages/relics/nft-minter-tutorial-main/minter-starter-files/src/cursed-cauldron.gif'
import charmcrystal from 'D:/NFT_development/front_end/new-app-pages/relics/nft-minter-tutorial-main/minter-starter-files/src/charm-crystal.gif'
import powergemgif from 'D:/NFT_development/front_end/new-app-pages/relics/nft-minter-tutorial-main/minter-starter-files/src/charm-crystal.gif'
import pleasewait from 'D:/NFT_development/front_end/new-app-pages/relics/nft-minter-tutorial-main/minter-starter-files/src/pleasewait1.PNG'

const Minter = (props) => {

  function addWalletListener() {
  if (window.ethereum) {
    window.ethereum.on("accountsChanged", (accounts) => {
      if (accounts.length > 0) {
        setWallet(accounts[0]);
        setStatus("👆🏽 Write a message in the text-field above.");
      } else {
        setWallet("");
        setStatus("🦊 Connect to Metamask using the top button.");
      }
    });
  } else {
    setStatus(
      <p>
        {" "}
        🦊{" "}
        <a target="_blank" href={`https://metamask.io/download.html`}>
          You must install Metamask, a virtual Ethereum wallet, in your
          browser.
        </a>
      </p>
    );
    }
  }

  //State variables
  const [ownership, setAreYouOwner] = useState("");
  const [upgradestatus, setupgradeStatus] = useState("");
  const [pixaBalance, setPixaBalance] = useState("");
  const [walletAddress, setWallet] = useState("");
  const [wargs, setWargsMinted] = useState("");
  const [displayRank, setRank] = useState("");
  const [tokenID, setToken] = useState("");
  const [tokenURI, setTokenURI] = useState("");
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setURL] = useState("");
  const [wizstatus, setWizStatus] = useState(""); 
  useEffect(async () => { //TODO: implement
    const {address, status} = await getCurrentWalletConnected();
    const {currentwargsminted} = await checkWargsMinted();
    setWallet(address);
    setStatus(status);
    setWargsMinted(currentwargsminted)
    setTokenURI(powergemgif);
    setupgradeStatus("currently selected.")    
    const {walletpixabalance} = await checkPixaBalance(address)
    setPixaBalance(walletpixabalance)
    addWalletListener(); 
  }, []);


  const onTokenChange = async (num) => {
    setAreYouOwner("")
    setTokenURI(pleasewait)
    setRank("") 
    setAreYouOwner("please wait... ⏱️") 
    setToken(num)
    const { tokenURI, displayRank,ownership } = await tokenURIfinder(num, walletAddress);   
    setTokenURI("https://" + tokenURI.substring(7) + ".gif")
    setRank(displayRank)
    setAreYouOwner(ownership)
  };


  const connectWalletPressed = async () => { //TODO: implement
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

  const onMintRelicPressed = async () => { //TODO: implement
      const { status } = await mintRelic(); 
  };

  const onMintThreeWargPressed = async () => { //TODO: implement
      const { status } = await mintThreeWarg();
  };

  const onMintWargPixaPressed = async () => { //TODO: implement
      const { status } = await mintPixaNFT(walletAddress); 
  };

  const onClaimPressed = async () => { //TODO: implement
      const { status } = await claimVortical();
  };

  const onClaimLoopPressed = async () => { //TODO: implement
      const { status } = await claimVorticalLoop();
  };

  const onCheckPressed = async () => { //TODO: implement
      const { wizstatus } = await checkDopplers(name);
    setWizStatus(wizstatus);  
  };

  const onUpgrade1Pressed = async () => { //TODO: implement
    setupgradeStatus("upgrading...");  
    const { status } = await upgradeGem(walletAddress, tokenID, 1);
  };

  const onUpgrade2Pressed = async () => { //TODO: implement
    setupgradeStatus("upgrading...");  
    const { status } = await upgradeGem(walletAddress, tokenID, 2);
  };

  const onUpgrade3Pressed = async () => { //TODO: implement
    setupgradeStatus("upgrading...");  
    const { status } = await upgradeGem(walletAddress, tokenID, 3);
  };

  const onUpgrade4Pressed = async () => { //TODO: implement
    setupgradeStatus("upgrading...");  
    const { status } = await upgradeGem(walletAddress, tokenID, 4);
  };

  const onUpgrade5Pressed = async () => { //TODO: implement
    setupgradeStatus("upgrading...");  
    const { status } = await upgradeGem(walletAddress, tokenID, 5);
  };


  
  return (
    <div className="Minter">
<div id="stars"></div>
<div id="stars2"></div>
<div id="stars3"></div>

<h5>By using this webpage you agree to the terms & conditions outlined at the bottom of this page. Disclaimer: Use this site at your own risk.</h5>       

<h1>
          <div>
      <img src={pixapng} alt="Image" height={45} width={100} border={0}/>
      </div>
</h1>
      <h2> <a href="https://wizards.pixanft.com" rel="noreferrer">  🧙‍♂️</a>  <a href="https://witches.pixanft.com" rel="noreferrer">  🧙‍♀️</a> <a href="https://wyverns.pixanft.com" rel="noreferrer">  🐲</a> <a href="https://wargs.pixanft.com" rel="noreferrer">  🐺</a>  <a href="https://brews.pixanft.com" rel="noreferrer">  🍺</a> <a href="https://limitededitions.pixanft.com" rel="noreferrer">  🖼️</a> </h2>
      <h5>Click an emoji to navigate to other pages!</h5>         
      <h2> <a href="https://tokens.pixanft.com" rel="noreferrer">  💰</a>  <a href="https://relics.pixanft.com" rel="noreferrer">  🗡️</a> <a href="https://wizardao.pixanft.com" rel="noreferrer">  🎩</a> <a href="https://tomes.pixanft.com" rel="noreferrer">  📖</a> <a href="https://land.pixanft.com" rel="noreferrer">  🏡</a> <a href="https://badges.pixanft.com" rel="noreferrer">  📛</a> </h2>
           

      <button id="walletButton" onClick={connectWalletPressed}>
        {walletAddress.length > 0 ? (
          "Connected: " +
          String(walletAddress).substring(0, 6) +
          "..." +
          String(walletAddress).substring(38)
        ) : (
          <span>Connect Wallet</span>
        )}
      </button>
  


      <h1 > Relics </h1>
      <h1 >🗡️ of 🗡️</h1>
      <h1 >the</h1>
      <h1 >PixaRealm</h1>
      <h1 >_________</h1>

<h4>The Relics of the PixaRealm collection is a series of limited edition NFTs that depict some of the most iconic and powerful objects in the PixaRealm. These relics have played a significant role in shaping the history of the realm and are highly sought after by collectors. The collection includes the Frost Blade, a powerful weapon that is said to have the ability to freeze time itself; the Ruby Bow, a bow imbued with the power of fire that has been used to defeat some of the realm's most formidable foes; the Cursed Cauldron, a mysterious object that is rumored to be able to grant wishes, but at a terrible cost; and the Charm Crystal, a powerful crystal that is said to have the ability to grant great fortune to those who possess it. There are 10 relics to be discovered, and will be available to mint periodically. This collection is free to mint, but is time-limited, so act fast to get your hands on these rare and coveted NFTs! For more background lore on the PixaRealm, visit our <a href="https://docs.pixanft.com" rel="noreferrer">docs page</a></h4>

<h4>The relics are ERC721 tokens minted on the Ethereum blockchain. They are the first "item" NFT collection and were released in 2022. For more information about the other NFT collections that exist in the PixaRealm, visit our <a href="https://pixanft.com" rel="noreferrer">  website</a></h4>

<h4> Scroll down to mint a relic NFT of your own, or check out the collection on <a href="https://opensea.io/collection/relics-of-the-pixarealm" rel="noreferrer">  OpenSea</a></h4>

<h1>_________</h1>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>

      <h1 >Current Mint</h1>
      <h1>_________</h1>
      <h4>Mint "The Charm Crystal" NFT for free! </h4>
      <h4>ENSURE WALLET IS CONNECTED TO ETHEREUM</h4>
      <p>
      </p>
      
        <h1>
          <div>
      <img src={charmcrystal} alt="Image" height={200} width={200} border={10}/>
      </div>
</h1>

<h4>Charm Crystals Minted: {wargs - 294} </h4>

      <button id="mintButton" onClick={onMintRelicPressed}>
        Mint 
      </button>
 

<h5>Only available for a limited time. Notice will be given on twitter and discord before minting closes.</h5>


<h4> Check out your new Charm Crystal on <a href="https://opensea.io/collection/relics-of-the-pixarealm" rel="noreferrer">  OpenSea</a> and join the community in <a href="https://discord.gg/pixa" rel="noreferrer">Discord</a></h4>


<h1>_________</h1>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>

<h1>Previous Mints</h1>
<h1>_________</h1>
<h4>These relics were available to be minted previously, but are now locked forever!</h4>

<br>
      </br>
      <br>
      </br>



<h4> <a href="https://opensea.io/collection/relics-of-the-pixarealm?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Name&search[stringTraits][0][values][0]=Cursed%20Cauldron" rel="noreferrer"> The Cursed Cauldron </a> </h4>
<h5>Total minted: 203</h5>



<h1>
          <div>
      <img src={cursedcauldron} alt="Image" height={200} width={200} border={10}/>
      </div>
</h1>



      <br>
      </br>
      <br>
      </br>


      <h4> <a href="https://opensea.io/collection/relics-of-the-pixarealm?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Name&search[stringTraits][0][values][0]=Ruby%20Bow" rel="noreferrer"> The Ruby Bow </a> </h4>
      <h5>Total minted: 55</h5>
      <h1>
          <div>
      <img src={rubybow} alt="Image" height={200} width={200} border={10}/>
      </div>
</h1>


      <br>
      </br>
      <br>
      </br>

      <h4> <a href="https://opensea.io/collection/relics-of-the-pixarealm?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Name&search[stringTraits][0][values][0]=Frost%20Blade" rel="noreferrer"> The Frost Blade </a> </h4>
      <h5>Total minted: 36</h5>
      <h1>
          <div>
      <img src={frostblade} alt="Image" height={200} width={200} border={10}/>
      </div>
</h1>

<br>
      </br>
      <br>
      </br>

<h1>_________</h1>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>

<h3>Important Links:</h3>

<h4><a href="https://etherscan.io/address/0x0f1255D9e644195cc6a040adFe7EFe2fa7d64fCe" rel="noreferrer">  Relics Contract</a> </h4>

<h4><a href="https://linktr.ee/pixanft" rel="noreferrer">  Linktree</a> </h4>

<h4> <a href="https://opensea.io/PixaDeployer?tab=created" rel="noreferrer">  OpenSea Collections</a> </h4>

<h4><a href="https://discord.gg/pixa" rel="noreferrer">  Discord</a> </h4>

<h4><a href="https://twitter.com/pixa_nft" rel="noreferrer">  Twitter </a></h4>

<br></br>


<h3>Terms & Conditions:</h3>

<h9>(applies to all Pixa sites & smart contracts) - Use this site at your own risk. Pixa is not liable for any losses from the use of this site or associated smart contracts. Your purchase of Pixa NFTs does NOT CONSTITUTE AN INVESTMENT. Purchase of these NFTs from Pixa is meant for entertainment purposes only. Pixa makes no warranties regarding future value, express or implied, to the public or to buyers. Pixa does not offer refunds on purchased NFTs. Only purchase if you wish to own the artwork and nothing else. Only purchase with the current state of the project in mind and the assumption that no future value will be delivered. Pixa NFTs and $PIXA tokens are not currency and do not convey ownership in Pixa or its assets. You are purchasing a digital collectible, we do not guarantee any future development around these NFTs. $PIXA tokens are not shares or securities of any type. Holding a token does not entitle the holder to any ownership or other interest in Pixa (formerly known as PixaLyfe), future business operations, future revenue, or any control or ability to bind the entity. $PIXA tokens are merely a means by which you may be able to utilize certain services such as changing aspects of the artwork in your Pixa NFT. There is no guarantee that the PixaRealm platform will actually be developed, although we guarantee that it is our intention to do so.</h9>

<h1>
          <div>
      <img src={pixagif} alt="Image" height={200} width={200} border={0}/>
      </div>
</h1>
    </div>
  );
};



export default Minter;
